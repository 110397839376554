.inquiry__display-setting {
  padding-top: 24px;
}
.inquiry__table {
  padding-top: 16px;
  overflow-x: auto;
  @media screen and (max-width: $tablet_point) {
    padding-top: 24px;
  }
}
.inquiry__pagenation {
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: $tablet_point) {
    justify-content: center;
  }
}

.inquiry-form, .inquiry-confirm, .inquiry-finish, .inquiry-detail {
  background-color: $c-white;
}

.inquiry-detail__container {
  width: 900px;
  margin: 0 auto;
  padding: 40px 0;
  @media screen and (max-width: $pc_point) {
    width: 100%;
    padding: 24px 0 32px;
  }
  &.with--input {
    @media screen and (max-width: $pc_point) {
      padding-bottom: 0;
    }
    .inquiry-detail__comment-container{
      @media screen and (max-width: $pc_point) {
        margin-bottom: 0;
      }
    }
  }
}

.inquiry-detail__comment-container {
  margin: 40px 0 32px;
  border-bottom: 1px solid $c-middleGray;
  @media screen and (max-width: $tablet_point) {
    margin-bottom: 24px;
  }
}

.inquiry-detail__button {
  width: 400px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
    padding: 0 16px;
  }
}