.terms {
  background-color: $c-white;
  box-shadow: 1px 1px 2px $c-box-shadow;
}
.terms__container {
  width: 900px;
  margin: 0 auto;
  padding: 40px 0 158px;
  @media screen and (max-width: $pc_point) {
    width: 100%;
    padding: 24px 16px 32px;
  }
}
.terms__text {
  font-size: 1.4rem;
  line-height: 1.5;
}
.terms__section {
  padding-top: 60px;
  @media screen and (max-width: $pc_point) {
    padding-top: 24px;
  }
  .title-sub {
    +.terms__text {
      padding-top: 24px;
      @media screen and (max-width: $pc_point) {
        padding-top: 16px;
      }
    }
  }
  ol {
    list-style-type: decimal;
    padding-left: 19px;
    li {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }
}