%button {
  width: 100%;
  height: 47px;
  margin: auto;
  position: relative;
}

%button__body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 7px 0 5px;
  font-size: 1.6rem;
  font-weight: bold;
  word-break: break-all;
  transition-duration: 0.3s;
}

// ベタ塗りのボタン
.button {
  @extend %button;
  &::after {
    position: absolute;
    content: "";
    height: 3px;
    width: 100%;
    background-color: $c-darkGreen;
  }
  &:active {
    &::after {
      background-color: $c-lightGreen;
      opacity: 0.7;
    }
  }
  .button__body {
    @extend %button__body;
    .link-text {
      color: $c-white;
    }

    color: $c-white;
    background-color: $c-lightGreen;
    &:hover {
      opacity: 0.7;
    }
    &:active {
      opacity: 0.7;
    }
  }
  &--red {
    @extend .button;
    &::after {
      background-color: $c-darkRed;
    }
    .button__body {
      background-color: $c-red;
    }
  }
}
// ベタ塗りのボタン中サイズ
.button__medium {
  @extend .button;
  height: 37px;
  &--gray {
    @extend .button__medium;
    &::after {
      background-color: $c-dark3;
      left: 0;
    }
    .button__body {
      background-color: $c-dark2;
    }
  }
  &--green {
    @extend .button__medium;
    &::after {
      background-color: $c-darkGreen;
    }
    .button__body {
      background-color: $c-lightGreen;
    }
  }
  &--red {
    @extend .button__medium;
    &::after {
      background-color: $c-darkRed;
    }
    .button__body {
      background-color: $c-red;
    }
  }
}