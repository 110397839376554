@charset "utf-8";
// -----------------------------------
// color
// -----------------------------------
$c-black: #333;
$c-black2: #666;
$c-black3: #000;
$c-dark: #979797;
$c-dark2: #999;
$c-dark3: #818181;
$c-dark4: #888;
$c-gray: #f2f2f4;
$c-gray2: #ddd;
$c-gray3: #aaa;
$c-gray4: #e4e9e8;
$c-gray5: #f2f4f4;
$c-teramoto-dark: #2d3934;
$c-teramoto-light: #2f994a;
$c-lightGreen : #30994b;
$c-darkGreen : #267b3b;
$c-middleGray: #e5e5e5;
$c-red: #d53030;
$c-darkRed: #9a1e1e;
$c-white: #fff;
$c-lightBlue: #e4eff6;
$c-blue: #4e9cc4;
$c-blue2: #3091a9;
$c-error: #cb0101;
$c-error-bg: #ffe8e8;
$c-selected: #4e9cc4;
$c-box-shadow: rgba(0,0,0,0.1);
$c-hover-green: #e9f2eb;


// -----------------------------------
// column width
// -----------------------------------
$maxWidth: 980px;
// -----------------------------------
// font-family
// -----------------------------------
$fontFamily: "Noto Sans", "ヒラギノ角ゴ Pro", Meiryo, "メイリオ",
  Arial, sans-serif;
// $fontFamily: "Noto Sans JP", sans-serif;
$fontFamily: "Noto Sans", sans-serif;
// $fontFamily: 'Noto Serif JP', serif;
$fontCatch: fot-tsukuardgothic-std, sans-serif;
// -----------------------------------
// Break Points (PCファースト)
// -----------------------------------
//※PCファーストと、モバイルファーストのブレークポイントをなるべく混ぜない
// $pc_point: 959.98px;
$pc_point: 1100px;
$tablet_point: 768px;
$sp_point: 479.98px;
//iPad Pro以下
//@media screen and (max-width: $pc_point) {}
//iPad以下
//@media screen and (max-width: $tablet_point) {}
//iPad以下かつ横持ち
//@media screen and (max-width: $tablet_point) and (orientation: landscape)  {}
//iPad以下かつ縦持ち
//@media screen and (max-width: $tablet_point) and (orientation: portrait) {}
//スマートフォン以下
//@media screen and (max-width: $sp_point_mf) {}
// -----------------------------------
// Break Points (モバイルファースト)
// -----------------------------------
$pc_point_mf: 1024px;
$tablet_point_mf: 768px;
$sp_point_mf: 480px;
//デバイス横持ち
//@media screen and (orientation: landscape) {}
//デバイス縦持ち
//@media screen and (orientation: portrait) {}
//iPad以上
//@media screen and (min-width: $sp_point_mf) {}
//iPad以上かつ横持ち
//@media screen and (min-width: $sp_point_mf) and (orientation: landscape)  {}
//iPad以上かつ縦持ち
//@media screen and (min-width: $sp_point_mf) and (orientation: portrait) {}
//iPad Pro以上
//@media screen and (min-width: $tablet_point_mf) {}
//PC以上
//@media screen and (min-width: $pc_point_mf) {}
//タブレットだけ ※メディアクエリが複雑なのでなるべく使わない
//@media screen and (min-width: $sp_point_mf) and (max-width: $pc_point_mf) {}
//iPad Proだけ ※メディアクエリが複雑なのでなるべく使わない
//@media screen and (min-width: $tablet_point_mf) and (max-width: $pc_point_mf) {}
//iPadだけ ※メディアクエリが複雑なのでなるべく使わない
//@media screen and (min-width: $sp_point_mf) and (max-width: $tablet_point_mf) {}


// -----------------------------------
// レスポンシブ 出しわけ
// -----------------------------------
.only--pc {
  @media screen and (max-width: $tablet_point) {
    display: none;
  }
}
.only--upper-1100 {
  @media screen and (max-width: $pc_point) {
    display: none;
  }
}
.only--under-1100 {
  display: none;
  @media screen and (max-width: $pc_point) {
    display: block;
  }
}
.only--sp {
  display: none;
  @media screen and (max-width: $tablet_point) {
    display: block;
  }
}