.account__display-setting {
  padding-top: 24px;
}
.account__table {
  padding-top: 16px;
  overflow-x: auto;
}
.account__pagenation {
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: $tablet_point) {
    justify-content: center;
  }
}

//アカウント新規登録画面
.account-form, .account-confirm, .account-finish {
  background-color: $c-white;
  box-shadow: 1px 1px 2px $c-box-shadow;
}