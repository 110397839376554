.finish__container {
  width: 900px;
  margin: 0 auto;
  padding: 40px 0 176px;
  @media screen and (max-width: $pc_point) {
    width: auto;
    padding: 32px 0;
  }
}
.finish__text {
  font-size: 1.6rem;
  line-height: 1.6;
  padding-bottom: 40px;
  border-bottom: 1px solid $c-teramoto-light;
  @media screen and (max-width: $pc_point) {
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 0 16px 24px;
  }
}
.finish__button {
  padding-top: 32px;
  width: 180px;
  margin: 0 auto;
  @media screen and (max-width: $pc_point) {
    padding-top: 24px;
  }
  &--multi {
    @extend .finish__button;
    width: 384px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $pc_point) {
      width: 100%;
      max-width: 414px;
      padding: 24px 15px 0;
    }
    .button__medium--gray {
      width: 180px;
      @media screen and (max-width: $pc_point) {
        width: 50%;
        max-width: 180px;
      }
      +.button__medium--gray {
        @media screen and (max-width: $pc_point) {
          margin-left: 11px;
        }
      }
    }
  }
}