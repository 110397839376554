.text-error {
  padding-top: 8px;
  font-size: 1.4rem;
  color: $c-error;
  line-height: 1.5;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.3rem;
  }
  &:empty {
    padding-top: 0;
  }
}
.text-error--box {
  margin-bottom: 24px;
  background-color: $c-error-bg;
  border: solid $c-error 1px;
  .text-error {
    padding: 16px 15px;
    line-height: 1.6;
    @media screen and (max-width: $tablet_point) {
      font-size: 1.4rem;
    }
  }
}
