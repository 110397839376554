.confirm__container {
  width: 900px;
  margin: 0 auto;
  padding: 40px 0;
  @media screen and (max-width: $pc_point) {
    width: 100%;
    padding: 32px 0;
  }
}
.confirm__text {
  font-size: 1.6rem;
  padding-bottom: 40px;
  border-bottom: 1px solid $c-teramoto-light;
  @media screen and (max-width: $pc_point) {
    font-size: 1.4rem;
    padding: 0 16px 24px;
    line-height: 1.5;
  }
}
.confirm__wrapper {
  display: flex;
  padding: 24px 0;
  border-bottom: 1px solid $c-middleGray;
  @media screen and (max-width: $pc_point) {
    display: block;
    padding: 24px 16px 16px;
  }
  +.confirm__wrapper {
    @media screen and (max-width: $pc_point) {
      padding-top: 16px;
    }
  }
  .form__category {
    padding-top: 0;
  }
}
.confirm__content {
  font-size: 1.6rem;
  width: 700px;
  line-height: 1.5;
  @media screen and (max-width: $pc_point) {
    font-size: 1.4rem;
    width: auto;
  }
}

// アカウント登録で使われている確認画面
.confirm__container {
  &.is-account {
    .confirm__wrapper {
      align-items: center;
      &.is-multi {
        .confirm__content {
          min-width: 115px;
          width: auto;
        }
      }
      &.is-flex {
        @media screen and (max-width: $pc_point) {
          display: flex;
        }
        .form__category {
          @media screen and (max-width: $pc_point) {
            padding-bottom: 0;
            width: auto;
            padding-right: 24px;
          }
        }
      }
    }
  }
}