.question {
  font-size: 1.6rem;
  font-weight: bold;
  padding: 21px 16px 21px 67px;
  background-color: $c-gray4;
  position: relative;
  pointer-events: none;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 13px 32px 13px 51px;
    pointer-events: auto;
  }
  &::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 32px;
    background: url(./../img/common/icon-question.svg);
    left: 16px;
    top: 14px;
    @media screen and (max-width: $tablet_point) {
      left: 8px;
      top: 50%;
      margin-top: -16px;
    }
  }
  &::after {
    @media screen and (max-width: $tablet_point) {
      position: absolute;
      content: "";
      top: 50%;
      right: 15px;
      width: 9px;
      height: 9px;
      border-top: 2px solid $c-black;
      border-right: 2px solid $c-black;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      margin-top: -5px;
    }
  }
  &.is-open {
    &::after {
      @media screen and (max-width: $tablet_point) {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        margin-top: -3px;
      }
    }
  }
}
.answer {
  font-size: 1.6rem;
  padding: 21px 16px 6px 67px;
  position: relative;
  line-height: 1.3;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.4rem;
    padding-left: 51px;
    padding-bottom: 16px;
    line-height: 1.5;
    display: none;
  }
  &::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 32px;
    background: url(./../img/common/icon-answer.svg);
    left: 16px;
    top: 14px;
    @media screen and (max-width: $tablet_point) {
      left: 8px;
      top: 16px;
    }
  }
}