.link--faq {
  display: inline-block;
  font-size: 1.6rem;
  color: $c-black;
  font-weight: bold;
  padding: 0 0 20px 34px;
  border-bottom: 1px solid $c-teramoto-light;
  width: 284px;
  position: relative;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.4rem;
    width: 47%;
    padding: 16px 0 12px 34px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 24px;
    height: 24px;
    background: url(./../img/common/icon-arrow.svg);
    left: 0;
    bottom: 16px;
    @media screen and (max-width: $tablet_point) {
      bottom: 8px;
    }
  }
}