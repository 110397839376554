
.top {
  margin-top: -15px;
  @media screen and (max-width: $tablet_point) {
    margin-top: 0;
  }
}
.top__link-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1215px;
  margin: 0 auto;
  transform: translate(15px);
  @media screen and (max-width: $tablet_point) {
    transform: translate(0px);
  }
}
.top__link-item {
  width: 390px;
  height: 160px;
  margin-right: 15px;
  margin-top: 15px;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
    height: auto;
    margin-right: 0;
    max-width: none;
    padding-top: 8px;
    margin-top: 0;
  }
  &:first-of-type {
    @media screen and (max-width: $tablet_point) {
      padding-top: 0;
    }
  }
  &.top__product-info {
    img {
      @media screen and (max-width: $tablet_point) {
        width: 40px;
        height: 64px;
      }
    }
  }
  &.top__order-history {
    img {
      @media screen and (max-width: $tablet_point) {
        width: 45px;
        height: 45px;
      }
    }
  }
  &.top__inquiry {
    img {
      @media screen and (max-width: $tablet_point) {
        width: 45px;
        height: 39px;
      }
    }
  }
  &.top__faq {
    img {
      @media screen and (max-width: $tablet_point) {
        width: 45px;
        height: 45px;
      }
    }
  }
  &.top__account-info {
    img {
      @media screen and (max-width: $tablet_point) {
        width: 40px;
        height: 54px;
      }
    }
  }
  &.top__terms {
    img {
      @media screen and (max-width: $tablet_point) {
        width: 40px;
        height: 50px;
      }
    }
  }
}
.top__text {
  font-size: 1.4rem;
  padding-top: 30px;
  text-align: center;
  @media screen and (max-width: $tablet_point) {
    padding-top: 24px;
  }
}