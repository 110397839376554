.logo-header {
  text-align: center;
}
.logo-header__logo-container {
  padding-top: 40px;
  img {
    @media screen and (max-width: $tablet_point) {
      width: 100px;
      height: 58px;
    }
  }
}