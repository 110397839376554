html {
  font-size: 62.5%;
}
body {
  font-family: $fontFamily !important;
  line-height: inherit !important;
  color: $c-black !important;
  background-color: $c-gray;
  &.is-fixed {
    //  width: 100%;
    //  height: 100%;
    overflow: hidden;
  }
}
.l-container {
  &--login {
    .l-main {
      padding-top: 40px;
    }
    .l-content {
      padding: 0 0 100px;
      @media screen and (max-width: $tablet_point) {
        padding: 0 16px 100px;
      }
    }
  }
}
.l-content {
  padding: 32px 32px 144px;
  @media screen and (max-width: $tablet_point) {
    padding: 24px 16px 40px;
  }
}
