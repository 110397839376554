.faq {
  background-color: $c-white;
  box-shadow: 1px 1px 2px $c-box-shadow;
}
.faq__container {
  width: 100%;
  max-width: 932px;
  margin: 0 auto;
  padding: 40px 16px 60px;
  @media screen and (max-width: $tablet_point) {
    padding: 16px 16px 32px;
  }
}
.faq__navigation {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $tablet_point) {
    flex-wrap: wrap;
  }
}
.faq__section__container {
  padding-top: 24px;
}
.faq__section__block {
  +.faq__section__block {
    padding-top: 32px;
    @media screen and (max-width: $tablet_point) {
      padding-top: 8px;
    }
  }
}