.error__message {
  width: 100%;
  background-color: $c-error-bg;
  border: solid 1px $c-error;
  text-align: center;
  padding: 14px 0;
  margin-bottom: 20px;
  border-radius: 3px;
  line-height: 1.5;
  @media screen and (max-width: $tablet_point) {
    padding: 14px 16px;
    text-align: left;
  }
}
.error__message__text {
  font-size: 1.4rem;
  color: $c-error;
}