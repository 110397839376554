.order-detail {
  background-color: $c-white;
  box-shadow: 1px 1px 2px $c-box-shadow;
}
.order-detail__content {
  padding: 40px 40px 61px;
  @media screen and (max-width: $tablet_point) {
    padding: 24px 0;
  }
}
.order-detail__content-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid $c-teramoto-light;
  @media screen and (max-width: $tablet_point) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px 24px 16px;
  }
}
.order-detail__content-heading__left {
  display: flex;
  @media screen and (max-width: $tablet_point) {
    flex-direction: column;
  }
}
.order-detail__content-heading__title {
  font-size: 1.8rem;
  font-weight: bold;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.6rem;
  }
  span {
    color: $c-teramoto-light;
  }
  +.order-detail__content-heading__title {
    padding-left: 60px;
    @media screen and (max-width: $tablet_point) {
      padding-left: 0;
      padding-top: 8px;
    }
  }
}
.order-detail__content-heading__right {
  .product-detail__pdf-link {
    padding-top: 0;
    @media screen and (max-width: $tablet_point) {
      padding-top: 27px;
    }
    &::before {
      top: -10px;
      @media screen and (max-width: $tablet_point) {
        top: 17px;
      }
    }
  }
}
.order-detail__content-detail__container {
  display: flex;
  padding-top: 24px;
  @media screen and (max-width: $tablet_point) {
    display: block;
    padding: 16px 16px 0;
  }
}
.order-detail__content-detail__category {
  font-size: 1.6rem;
  font-weight: bold;
  color: $c-teramoto-light;
  min-width: 100px;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.4rem;
  }
}
.order-detail__content-detail__text {
  font-size: 1.6rem;
  padding-left: 24px;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.4rem;
    padding-left: 0;
    padding-top: 8px;
  }
}
.order-detail__table {
  overflow-x: auto;
}