.inquiry-detail__input {
  padding: 30px;
  background-color: $c-gray5;
  @media screen and (max-width: $tablet_point) {
    padding: 24px 0 0;
    background-color: $c-gray;
  }
}
.inquiry-detail__input__title {
  font-size: 2rem;
  color: $c-teramoto-light;
  font-weight: bold;
  text-align: center;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.8rem;
    padding-bottom: 8px;
  }
}
.inquiry-detail__form__wrapper {
  padding-top: 27px;
  @media screen and (max-width: $tablet_point) {
    padding-top: 16px;
  }
  .form--text {
    width: 350px;
    @media screen and (max-width: $tablet_point) {
      width: 100%;
    }
    .form__input-text {
      &::placeholder {
        font-size: 1.6rem;
      }
    }
  }
  .form__textarea {
    height: 159px;
  }
}
.inquiry-detail__form__category {
  font-size: 1.4rem;
  font-weight: bold;
  color: $c-teramoto-light;
  padding-bottom: 6px;
  span {
    color: $c-red;
  }
}
.inquiry-detail__form__button {
  width: 400px;
  margin: 0 auto;
  padding-top: 32px;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
    max-width: 432px;
    padding: 24px 16px 0;
  }
}