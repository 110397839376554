.maintenance {
  text-align: center;
  padding: 40px 0 58px;
  @media screen and (max-width: $tablet_point) {
    padding: 32px 0 44px;
  }
}
.maintenance__title {
  font-size: 2.2rem;
  font-weight: bold;
  @media screen and (max-width: $tablet_point) {
    font-size: 2rem;
  }
}
.maintenance__text {
  font-size: 1.6rem;
  padding-top: 40px;
  line-height: 1.5;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.4rem;
    padding-top: 24px;
  }
}