.title {
  padding-bottom: 40px;
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.36;
  color: $c-black;
  text-align: center;
  @media screen and (max-width: $tablet_point) {
    padding: 0 0 32px;
  }
  @media screen and (max-width: 375px) {
    padding: 0 16px 32px;
  }
  @media screen and (max-width: 320px) {
    padding: 0 0 32px;
  }
  &--top {
    @extend .title;
    @media screen and (max-width: $tablet_point) {
      font-size: 2.3rem;
    }
  }
}

.title--middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $tablet_point) {
    flex-direction: column;
    align-items: flex-start;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    padding-right: 20px;
    padding-left: 14px;
    font-size: 1.8rem;
    font-weight: bold;
    vertical-align: top;
    background-color: transparent;
    border-left: 0;
    &::before {
      position: absolute;
      left: 0;
      width: 4px;
      height: 22px;
      content: "";
      background-color: #df2718;
    }
  }
}
