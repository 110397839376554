.search__area {
  background-color: $c-gray4;
  padding: 0px 16px 16px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  @media screen and (max-width: $tablet_point) {
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 32px);
    margin-left: -16px;
  }
}
.search__area--small {
  display: flex;
  @media screen and (max-width: $tablet_point) {
    justify-content: space-between;
    width: 100%;
  }
  +.search__form {
    @media screen and (max-width: $tablet_point) {
      margin-top: 8px;
    }
  }
  .search__form {
    &.is-250 {
      @media screen and (max-width: $tablet_point) {
        width: 52%;
      }
    }
    &.is-150 {
      @media screen and (max-width: $tablet_point) {
        width: 44%;
      }
    }
  }
}
.search__form {
  width: 200px;
  margin-right: 12px;
  padding-top: 16px;
  @media screen and (max-width: $tablet_point) {
    width: 48%;
    margin-right: 0;
  }
  +.search__area--small {
    @media screen and (max-width: $tablet_point) {
      margin-top: 8px;
    }
  }
  &.is-150 {
    width: 150px;
    @media screen and (max-width: $tablet_point) {
      width: 100%;
    }
  }
  &.is-200 {
    @media screen and (max-width: $tablet_point) {
      width: 100%;
      padding-top: 8px;
    }
  }
  &.is-250 {
    width: 250px;
  }
  &.is-300 {
    width: 300px;
    @media screen and (max-width: $tablet_point) {
      width: 100%;
    }
  }
  &.is-400 {
    width: 400px;
    @media screen and (max-width: $tablet_point) {
      width: 100%;
      padding-top: 8px;
    }
  }
  &.is-first {
    @media screen and (max-width: $tablet_point) {
      padding-top: 16px;
    }
  }
  &.is-last {
    margin-right: 24px;
    @media screen and (max-width: $tablet_point) {
      margin-right: 0;
    }
  }
  &.is-multi {
    display: flex;
    width: 290px;
    justify-content: space-between;
    align-items: center;
    margin-right: 12px;
    @media screen and (max-width: $tablet_point) {
      width: 100%;
    }
    .form--text {
      @media screen and (max-width: $tablet_point) {
        width: 46%;
      }
    }
    .form__input-text {
      width: 130px;
      min-width: 0;
      @media screen and (max-width: $tablet_point) {
        width: 100%;
      }
    }
  }
  .form__select {
    font-size: 1.6rem;
    border: 1px solid $c-gray3;
    border-radius: 2px;
  }
}
.search__button__area {
  display: flex;
  margin-bottom: 3px;
  padding-top: 16px;
  @media screen and (max-width: $tablet_point) {
    padding-top: 16px;
    padding-left: 0;
  }
}
.search__button {
  width: 120px;
  +.search__button {
    margin-left: 12px;
  }
}
.search__text {
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
  color: $c-black2;
}

//注文履歴照会の2段になる検索欄
.search {
  background-color: $c-gray4;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  @media screen and (max-width: $tablet_point) {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding: 0 16px;
    justify-content: center;
  }
  .search__area {
    padding-right: 8px;
  }
  .search__button__area {
    padding-bottom: 16px;
    padding-left: 16px;
    @media screen and (max-width: $tablet_point) {
      padding: 8px 0 24px 0;
    }
  }
}
.search__area--multi {
  @media screen and (max-width: $tablet_point) {
    padding-top: 16px;
    width: 100%;
  }
  .search__form {
    padding-top: 0;
  }
}
.ui-datepicker {
  font-size: 1.6rem;
}

// アカウント一覧
.search__area--checkbox {
  display: flex;
  align-items: center;
  margin-right: 24px;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
    margin-right: 0;
  }
  .search__form {
    @media screen and (max-width: $tablet_point) {
      margin-right: 12px;
    }
  }
}
.search__checkbox {
  padding-top: 16px;
  @media screen and (max-width: $tablet_point) {
    min-width: 100px;
  }
  .form--checkbox {
    height: 40px;
  }
  .form__label {
    margin-top: 10px;
    @media screen and (max-width: $tablet_point) {
      margin-top: 4px;
    }
    &::before {
      background-color: $c-white;
    }
  }
}
.search__area {
  &.is-account {
    @media screen and (max-width: $tablet_point) {
      padding-top: 16px;
    }
    .search__form {
      +.search__form {
        @media screen and (max-width: $tablet_point) {
          padding-top: 8px;
        }
      }
      &.is-200 {
        @media screen and (max-width: $tablet_point) {
          padding-top: 0;
        }
      }
    }
  }
}