.inquiry-detail__number {
  font-size: 1.6rem;
  @media screen and (max-width: $pc_point) {
    padding: 0 16px;
    font-size: 1.4rem;
  }
  span {
    color: $c-teramoto-light;
    font-weight: bold;
  }
}
.inquiry-detail__title {
  font-size: 2.4rem;
  font-weight: bold;
  padding: 24px 0;
  border-bottom: 1px solid $c-teramoto-light;
  @media screen and (max-width: $pc_point) {
    font-size: 2rem;
    padding: 8px 16px 24px;
  }
}
.inquiry-detail__text {
  font-size: 1.6rem;
  line-height: 1.6;
  padding-top: 24px;
  @media screen and (max-width: $pc_point) {
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 24px 16px 0;
  }
}
.inquiry-detail__person {
  display: flex;
  padding-top: 16px;
  @media screen and (max-width: $pc_point) {
    flex-wrap: wrap;
    padding: 24px 16px 0;
  }
}
.inquiry-detail__person-category {
  font-size: 1.6rem;
  font-weight: bold;
  color: $c-teramoto-light;
  padding-right: 10px;
  padding-top: 16px;
  min-width: 74px;
  @media screen and (max-width: $pc_point) {
    width: 100%;
  }
}
.inquiry-detail__person-block {
  display: flex;
  flex-wrap: wrap;
  word-break: break-all;
}
.inquiry-detail__person-text {
  font-size: 1.6rem;
  padding-right: 40px;
  padding-top: 16px;
  @media screen and (max-width: $pc_point) {
    padding-top: 11px;
    font-size: 1.4rem;
    padding-right: 20px;
  }
  &:last-child {
    @media screen and (max-width: $pc_point) {
      padding-left: 0;
      padding-top: 8px;
      width: 100%;
    }
  }
}
.inquiry-detail__info {
  background-color: $c-gray5;
  padding: 22px 20px;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $pc_point) {
    padding: 16px;
    margin: 24px 16px 0;
    display: block;
  }
}
.inquiry-detail__info-text {
  font-size: 1.4rem;
  span {
    color: $c-teramoto-light;
    font-weight: bold;
  }
  + .inquiry-detail__info-text {
    @media screen and (max-width: $pc_point) {
      padding-top: 10px;
    }
  }
}