.nav-links {
  display: flex;
}
.nav-links__inner {
  display: flex;
  border: 1px solid $c-gray3;
  border-radius: 2px;
  height: 40px;
  margin: 0 16px;
}
.page-numbers {
  display: block;
  font-size: 1.7rem;
  width: 40px;
  color: $c-black;
  padding: 8px 15px;
  border-right: 1px solid $c-gray3;
  background-color: $c-white;
  &:last-child {
    border-right: 0;
  }
  &.next-last {
    border-right: 1px solid $c-gray3;
  }
  &.current {
    color: $c-white;
    background-color: $c-dark4;
    &:hover {
      opacity: 1;
    }
  }
  &.only--pc {
    @media screen and (max-width: $pc_point) {
      display: none;
    }
  }
  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.prev-first, .next-last {
  border: 1px solid $c-gray3;
  border-radius: 2px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-top: 2px solid $c-black;
    border-right: 2px solid $c-black;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 50%;
    left: 14px;
    margin-top: -6px;
  }
  &::after {
    @extend .next-last::before;
    left: 7px;
  }
}
.prev-first {
  &::before {
    transform: rotate(225deg);
    left: 19px;
  }
  &::after {
    transform: rotate(225deg);
    left: 12px;
  }
}
.prev, .next {
  position: relative;
  &::before {
    @extend .next-last::before;
    left: 12px;
  }
}
.prev {
  &::before {
    transform: rotate(225deg);
    left: 17px;
  }
}