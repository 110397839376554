@charset "utf-8";
.breadcrumb {
  width: 100%;
  padding: 12px 0 12px 30px;
  background-color: $c-teramoto-dark;
  @media screen and (max-width: $tablet_point) {
    padding: 12px 0 12px 16px;
  }
}

.breadcrumb__list {
  position: relative;
  display: flex;
  list-style: none;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display:none;
  }
}

.breadcrumb__item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  font-size: 12px;
  @media screen and (max-width: $tablet_point) {
    font-size: 11px;
  }
  + .breadcrumb__item {
    position: relative;
    padding-left: 16px;
    &::before {
      position: absolute;
      left: -5px;
      display: block;
      width: 7px;
      height: 7px;
      content: "";
      border-top: 1px solid #999;
      border-right: 1px solid #999;
      transform: rotate(45deg);
    }
  }
  .breadcrumb__anchor {
    text-decoration: underline;
    color: $c-white;
    &:hover {
      color: $c-teramoto-light;
      transition: 0.3s;
    }
  }

  &:last-child {
    font-weight: bold;
    .breadcrumb__anchor {
      pointer-events: none;
      text-decoration: none;
    }
  }
}
