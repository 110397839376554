.login {
  width: 540px;
  margin: 0 auto;
  background-color: $c-white;
  box-shadow: 1px 1px 2px $c-box-shadow;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
  }
  &.password {
    .login__button--medium {
      padding-top: 24px;
      @media screen and (max-width: $tablet_point) {
        padding-top: 16px;
      }
    }
  }
}
.login__container {
  padding: 32px 32px 40px;
  @media screen and (max-width: $tablet_point) {
    padding: 32px 16px 40px;
  }
}
.login__title {
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  @media screen and (max-width: $tablet_point) {
    font-size: 2rem;
  }
}
.login__text {
  font-size: 1.6rem;
  text-align: center;
  padding: 32px 0;
  line-height: 1.6;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.4rem;
    text-align: left;
    padding: 24px 0;
    line-height: 1.5;
  }
}
.login__subtext {
  font-size: 1.4rem;
  text-align: center;
  padding-top: 24px;
  line-height: 1.6;
  @media screen and (max-width: $tablet_point) {
    line-height: 1.5;
  }
}
.login__form {
  padding-top: 16px;
  width: 400px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
  }
  &:first-of-type {
    padding-top: 0;
  }
}
.login__button {
  width: 400px;
  margin: 0 auto;
  padding-top: 32px;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
    max-width: 400px;
    padding-top: 24px;
  }
  &--medium {
    width: 180px;
    margin: 0 auto;
    padding-top: 16px;
    @media screen and (max-width: $tablet_point) {
      padding-top: 24px;
    }
  }
}
.login__separate {
  text-align: center;
}
.login__separate-text {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 32px;
  position: relative;
  @media screen and (max-width: $tablet_point) {
    display: inline-block;
  }
  &::before, &::after {
    position: absolute;
    content: "";
    width: 160px;
    height: 1px;
    background-color: $c-gray3;
    top: 50%;
    @media screen and (max-width: $tablet_point) {
      width: 116px;
    }
  }
  &::before {
    left: 38px;
    @media screen and (max-width: $tablet_point) {
      left: auto;
      right: 63px;
    }
  }
  &::after {
    right: 38px;
    @media screen and (max-width: $tablet_point) {
      right: auto;
      left: 63px;
    }
  }
}

// 初回パスワード設定
.login__password-attention {
  width: 400px;
  background-color: $c-gray4;
  margin: 16px auto 0;
  padding: 10px;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
  }
}
.login__password-attention__text {
  font-size: 1.4rem;
  line-height: 1.6;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.2rem;
    line-height: 1.8;
  }
}

// パスワード再設定メール送付_完了メッセージ
.login__finish {
  display: flex;
  align-items: center;
  background-color: $c-lightBlue;
  border: 1px solid $c-blue;
  padding: 12px 16px;
  margin-bottom: 24px;
  width: 100%;
  @media screen and (max-width: $tablet_point) {
    align-items: flex-start;
  }
}
.login__finish-message {
  font-size: 1.4rem;
  line-height: 1.5;
  padding-left: 16px;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.2rem;
  }
}

// パスワード再設定_完了
.login__text {
  &.is-finish {
    padding-bottom: 0;
  }
}

// ワンタイムパスワード入力
.login__separate__line {
  display: block;
  height: 1px;
  width: 100%;
  background-color: $c-gray3;
  margin: 35px 0 8px;
}