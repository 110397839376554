.order-history__display-setting {
  padding-top: 24px;
}
.order-history__table {
  padding-top: 16px;
  overflow-x: auto;
}
.order-history__pagenation {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  @media screen and (max-width: $tablet_point) {
    justify-content: center;
  }
}