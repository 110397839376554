.title-sub {
  font-size: 2.4rem;
  font-weight: bold;
  padding-bottom: 24px;
  border-bottom: 1px solid $c-teramoto-light;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.8rem;
    padding-bottom: 16px;
  }
}
