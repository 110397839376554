.radio-list {
  display: flex;
  flex-wrap: wrap;
}
.radio-list__item {
  margin-right: 44px;
  line-height: 1.5;
  .form__label {
    font-size: 16px;
  }
}
.radio-list__item--primary {
  @extend.radio-list__item;

  margin-bottom: 10px;
}
.l-radio-list-button {
  display: flex;
  justify-content: flex-start;
  .button--tertiary {
    margin-top: 10px;
    margin-left: 0;
    @media screen and (max-width: 959.98px) {
      margin: auto;
    }
  }
}
