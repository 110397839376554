.inquiry-detail__comment {
  display: flex;
  padding: 30px;
  justify-content: space-between;
  border-top: 1px solid $c-middleGray;
  @media screen and (max-width: $pc_point) {
    display: block;
    padding: 24px 16px;
  }
}
.inquiry-detail__comment-icon {
  width: 60px;
  text-align: center;
  @media screen and (max-width: $pc_point) {
    text-align: left;
  }
}
.inquiry-detail__comment-content {
  width: 740px;
  @media screen and (max-width: $pc_point) {
    width: 100%;
    padding-top: 24px;
  }
}
.inquiry-detail__comment-category {
  font-size: 1.4rem;
  font-weight: bold;
  color: $c-teramoto-light;
  padding-bottom: 16px;
  @media screen and (max-width: $pc_point) {
    padding-bottom: 8px;
  }
  span {
    color: $c-red;
  }
}
.inquiry-detail__comment-text {
  font-size: 1.6rem;
  line-height: 1.6;
  &.is-small {
    font-size: 1.4rem;
  }
}
.inquiry-detail__comment-block {
  display: flex;
  padding-top: 32px;
  @media screen and (max-width: $pc_point) {
    display: block;
  }
  .inquiry-detail__comment-wrap {
    width: 50%;
    @media screen and (max-width: $pc_point) {
      width: 100%;
    }
    +.inquiry-detail__comment-wrap {
      margin-left: 40px;
      @media screen and (max-width: $pc_point) {
        padding-top: 24px;
        margin-left: 0;
      }
    }
    .form--text {
      width: 350px;
      @media screen and (max-width: $pc_point) {
        width: 100%;
      }
    }
  }
}
.inquiry-detail__comment-wrap {
  .form--textarea {
    .form__textarea {
      line-height: 1.5;
      @media screen and (max-width: $pc_point) {
        height: 227px;
        font-size: 1.4rem;
      }
    }
  }
}
.inquiry-detail__comment-block {
  &.is-edit {
    .inquiry-detail__comment-wrap {
      width: auto;
    }
  }
}
.inquiry-detail__comment-button {
  width: 120px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: $pc_point) {
    padding-top: 16px;
    margin: 0 auto;
  }
  .button__medium--green {
    margin: 0 auto;
  }
}
.inquiry-detail__comment-edit {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $pc_point) {
    display: block;
  }
}