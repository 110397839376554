.global-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 40px;
  color: $c-black;
  background-color: #fff;
  @media screen and (max-width: $pc_point) {
    justify-content: space-between;
    z-index: 3;
    width: 100%;
    height: 60px;
    padding: 0 16px;
  }
}
.global-header__logo-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 21px 0;
  @media screen and (max-width: $pc_point) {
    padding: 14px 0;
  }
}
.global-header__logo {
  a {
    display: block;
  }
  img {
    @media screen and (max-width: $pc_point) {
      width: 54px;
      height: 32px;
    }
  }
}
.global-header__menu-wrap {
  display: flex;
  padding-left: 90px;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: $pc_point) {
    flex-direction: column;
    width: auto;
    padding-left: 0;
  }
  .global-header__hamburger {
    display: none;
    @media screen and (max-width: $pc_point) {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    span {
      @media screen and (max-width: $pc_point) {
        display: inline-block;
        width: 28px;
        height: 2px;
        border-radius: 2px;
        background-color: $c-black;
        margin-top: 9px;
        position: relative;
      }
      &:first-of-type {
        @media screen and (max-width: $pc_point) {
          margin-top: 0;
        }
      }
    }
    &.is-open {
      &:before, &:after {
        @media screen and (max-width: $tablet_point) {
          position: absolute;
          content: "";
          width: 28px;
          height: 2px;
          background-color: $c-black3;
          transform: rotate(45deg);
          right: 0;
        }
      }
      &:after {
        @media screen and (max-width: $tablet_point) {
          transform: rotate(-45deg);
        }
      }
      span {
        @media screen and (max-width: $tablet_point) {
          display: none;
        }
      }
    }
  }
}
.global-header__menu-container {
  position: relative;
  display: flex;
  align-items: center;
  @media screen and (max-width: $pc_point) {
    display: none;
  }
  &.is-open {
    @media screen and (max-width: $pc_point) {
      position: absolute;
      width: 300px;
      display: block;
      right: 14px;
      top: 56px;
      background-color: $c-white;
      border-radius: 4px;
      box-shadow: 0px 5px 10px $c-box-shadow;
      border: 1px solid $c-dark;
    }
    @media screen and (max-width: $tablet_point) {
      position: fixed;
      display: block;
      width: 100%;
      height: 100%;
      right: 0;
      left: 0;
      top: 56px;
      border: 0;
      border-radius: 0;
      box-shadow: inset 0px 6px 4px -4px rgba(0, 0, 0, 0.16);
    }
  }
}
.header__menu {
  @media screen and (max-width: $pc_point) {
  }
  &__body {
    display: flex;
    @media screen and (max-width: $pc_point) {
      display: block;
    }
  }
  &__item {
    font-size: 14px;
    padding-right: 40px;
    @media screen and (max-width: $pc_point) {
      font-size: 14px;
      padding: 16px;
      border-bottom: 1px solid $c-gray2;
      &:last-child {
        border-bottom: 0;
      }
    }
    @media screen and (max-width: $tablet_point) {
      font-size: 1.6rem;
      padding: 21px;
    }
    &:last-child {
      padding-right: 0;
      @media screen and (max-width: $pc_point) {
        border-bottom: 0;
      }
      @media screen and (max-width: $tablet_point) {
        border-bottom: 1px solid $c-gray2;
      }
    }
    &.is-current {
      .header__menu__link {
        color: $c-teramoto-light;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          height: 6px;
          width: calc(100% + 40px);
          background-color: $c-teramoto-light;
          left: -20px;
          bottom: -41px;
          @media screen and (max-width: $pc_point) {
            display: none;
          }
        }
      }
    }
    &.other__site {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 15px;
        height: 12px;
        background: url(./../img/common/icon-blank.svg);
        background-repeat: no-repeat;
        background-size: cover;
        top: 50%;
        right: 23px;
        margin-top: -6px;
        @media screen and (max-width: $tablet_point) {
          right: auto;
          left: 300px;
        }
      }
    }
  }
  &__link {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: $c-black3;
    transition-duration: 0.3s;
    &:hover {
      color: $c-teramoto-light;
    }
  }
  &__list-hide {
    display: none;
    position: absolute;
    width: 300px;
    right: 24px;
    top: 76px;
    background-color: $c-white;
    border-radius: 4px;
    border: 1px solid $c-dark;
    z-index: 2;
    &.is-open {
      display: block;
    }
  }
  &__item-hide {
    font-size: 14px;
    padding: 16px;
    border-bottom: 1px solid $c-gray2;
    &:last-child {
      border-bottom: 0;
    }
    &.other__site {
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 15px;
        height: 12px;
        background: url(./../img/common/icon-blank.svg);
        background-repeat: no-repeat;
        background-size: cover;
        top: 50%;
        right: 23px;
        margin-top: -6px;
      }
    }
  }
  a {
    color: #000;
    transition-duration: 0.3s;
    &:hover {
      color: $c-teramoto-light;
    }
  }
}
.global-header__user {
  cursor: pointer;
}
.global-header__user__logo {
  position: relative;
  @media screen and (max-width: $pc_point) {
    display: none;
  }
  &:after {
    position: absolute;
    content: "";
    right: -14px;
    bottom: -3px;
    box-sizing: border-box;
    width: 8px;
    height: 6px;
    border: 6px solid transparent;
    border-left: 8px solid $c-black;
    transform: rotate(90deg);
  }
}
