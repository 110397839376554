// 商品情報照会ページなどで使われている表
.table {
  background-color: $c-white;
  width: 100%;
  min-width: 1036px;
  box-shadow: 1px 1px 2px $c-box-shadow;
  display: table;
  overflow-x: auto;
  @media screen and (max-width: $tablet_point) {
    display: block;
    -webkit-overflow-scrolling: touch;
  }
  div {
    display: table-row;
    width: 100%;
  }
  a {
    display: table-row;
    &:hover {
      background-color: $c-hover-green;
      transition : 0.3s;
    }
  }
  .table__category {
    p {
      font-size: 1.2rem;
      color: $c-teramoto-light;
      padding: 20px;
      border-bottom: 1px solid $c-teramoto-light;
      line-height: 1.5;
      @media screen and (max-width: $tablet_point) {
        padding: 20px 0 20px 20px;
        &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
      }
    }

  }
  p {
    font-size: 1.4rem;
    padding: 20px;
    border-bottom: 1px solid $c-middleGray;
    display: table-cell;
    color: $c-black;
    line-height: 1.5;
    @media screen and (max-width: $tablet_point) {
      font-size: 1.2rem;
      padding: 20px 0 20px 20px;
      &:first-child {
        padding-left: 16px;
      }
      &:last-child {
        padding-right: 16px;
      }
    }
  }
}

// 商品情報詳細で使われている表
.table__simple {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
  th {
    font-size: 1.4rem;
    font-weight: bold;
    color: $c-teramoto-light;
    padding: 14px 70px 14px 0;
    width: 140px;
    border-bottom: 1px solid $c-middleGray;
    line-height: 1.5;
    @media screen and (max-width: $tablet_point) {
      padding: 14px 40px 14px 16px;
    }
  }
  td {
    font-size: 1.4rem;
    padding: 14px 0;
    border-bottom: 1px solid $c-middleGray;
    line-height: 1.5;
    @media screen and (max-width: $tablet_point) {
      padding-right: 16px;
    }
  }
}

// 注文履歴系で使われている表
.table__wrapper {
  background-color: $c-white;
  box-shadow: 1px 1px 2px $c-box-shadow;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}
.table__heading {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: $tablet_point) {
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.table__heading-text {
  font-size: 1.6rem;
  font-weight: bold;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.4rem;
  }
  span {
    color: $c-teramoto-light;
  }
  +.table__heading-text {
    padding-left: 60px;
    @media screen and (max-width: $tablet_point) {
      padding-left: 0;
      padding-top: 8px;
    }
  }
}
.table__heading-left, .table__heading-right {
  display: flex;
  align-items: center;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
.table__heading-right {
  @media screen and (max-width: $tablet_point) {
    padding-top: 8px;
  }
}
.table__heading-button {
  width: 160px;
  padding-left: 60px;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
    padding-left: 0;
    padding-top: 16px;
  }
}
.table__order {
  width: 100%;
  min-width: 1036px;
  overflow-x: auto;
  @media screen and (max-width: $tablet_point) {
    display: block;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    min-width: 0;
  }
  th {
    font-size: 1.2rem;
    color: $c-white;
    background-color: $c-black2;
    padding: 4px 20px;
    white-space: nowrap;
    line-height: 1.5;
  }
  td {
    font-size: 1.4rem;
    padding: 20px;
    border-bottom: 1px solid $c-middleGray;
    white-space: nowrap;
    line-height: 1.5;
    a {
      color: $c-blue2;
      text-decoration: underline;
      &:hover {
        color: $c-teramoto-light;
        transition : 0.5s;
      }
    }
    &.is-large {
      white-space: normal;
      min-width: 170px;
    }
    &.product-name {
      width: 408px;
      white-space: normal;
      @media screen and (max-width: $tablet_point) {
        // white-space: nowrap;
        min-width: 300px;
      }
    }
  }
  &.is-detail {
    min-width: 1336px;
    @media screen and (max-width: $tablet_point) {
      min-width: 0;
    }
  }
}