.product-detail {
  background-color: $c-white;
  padding: 40px;
  @media screen and (max-width: $tablet_point) {
    padding: 32px 16px;
  }
  .title-page {
    padding: 0;
    @media screen and (max-width: $tablet_point) {
      padding-bottom: 24px;
    }
  }
}
.product-detail__container {
  display: flex;
  @media screen and (max-width: $tablet_point) {
    display: block;
  }
  img {
    @media screen and (max-width: $tablet_point) {
      width: 100%;
    }
  }
}
.product-detail__img {
  width: 400px;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
  }
}
.product-detail__info {
  padding-left: 40px;
  @media screen and (max-width: $tablet_point) {
    padding-left: 0;
  }
}
.product-detail__info-table {
  padding-top: 43px;
  @media screen and (max-width: $tablet_point) {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
}
.product-detail__info-appx {
  font-size: 1.4rem;
  display: block;
  padding-top: 32px;
  @media screen and (max-width: $tablet_point) {
    padding-top: 16px;
  }
}
.product-detail__pdf {
  padding-top: 40px;
  @media screen and (max-width: $tablet_point) {
    padding-top: 60px;
  }
}
.product-detail__pdf-title {
  font-size: 1.8rem;
  font-weight: bold;
}
.product-detail__pdf-link {
  font-size: 1.4rem;
  color: $c-blue2;
  text-decoration: underline;
  display: inline-block;
  padding: 24px 0 0 40px;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 32px;
    height: 32px;
    background: url(./../img/common/icon-pdf.png);
    left: 0;
    top: 14px;
  }
  &:hover {
    color: $c-teramoto-light;
    transition : 0.3s;
  }
}