.mySwiper2 {
  .swiper-slide {
    text-align: center;
    border: 1px solid $c-gray3;
    height: 382px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 380px;
      @media screen and (max-width: $tablet_point) {
        width: 100%;
      }
    }
  }
}
.swiper-button-next, .swiper-button-prev {
  color: $c-black3;
  &::after {
    font-size: 2.7rem;
    @media screen and (max-width: $tablet_point) {
      font-size: 2.4rem;
    }
  }
  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.swiper-button-prev {
  left: 5px;
  @media screen and (max-width: $tablet_point) {
    left: 0;
  }
}
.swiper-button-next{
  right: 5px;
  @media screen and (max-width: $tablet_point) {
    right: 0;
  }
}
.mySwiper {
  padding-top: 16px;
  @media screen and (max-width: $tablet_point) {
    padding-top: 12px;
  }
  .swiper-slide {
    border: 1px solid $c-gray3;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 74px;
      @media screen and (max-width: $tablet_point) {
        width: 100%;
      }
    }
    &:hover {
      opacity: 0.7;
      transition: 0.3s;
    }
  }
}