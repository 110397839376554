.form {
  position: relative;
}

.form__label {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
  font-size: 1.4rem;
  color: $c-black;
  &::before,
  &::after {
    display: inline-block;
    content: "";
  }
  &::before {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background-color: $c-gray4;
    border: solid 1px $c-gray3;
    border-radius: 4px;
  }
  &::after {
    position: absolute;
    display: none;
  }
}

// チェックボックス
.form--checkbox {
  @extend .form;
  @extend %error--check;
  .form__label {
    &::after {
      top: 2px;
      bottom: 0;
      left: 9px;
      width: 12px;
      height: 8px;
      margin: auto;
      border-bottom: 2px solid $c-white;
      border-left: 2px solid $c-white;
      transform: rotate(-50deg);
      transform-origin: left bottom;
    }
  }
}

// チェックタイプの基本スタイル
.form__input-check {
  position: absolute;
  opacity: 0;
  &:focus {
    & ~ .form__label {
      &::before {
        border-color: $c-selected;
      }
    }
  }
  &:checked {
    & ~ .form__label {
      &::before {
        background-color: $c-selected;
        border-color: $c-selected;
      }
      &::after {
        display: block;
      }
    }
  }
  &.is_error {
    + .form__label {
      &::before {
        background-color: $c-error-bg;
        border: solid 1px $c-error;
      }
    }
    &:checked {
      + .form__label {
        &::before {
          background-color: $c-selected;
          border-color: $c-selected;
        }
      }
    }
  }
}

// テキストタイプの基本スタイル
.form__input-text {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  font-size: 1.6rem;
  background-color: $c-white;
  border: solid 1px $c-gray3;
  border-radius: 2px;
  &::placeholder {
    // color: #575757;
    font-size: 1.6rem;
    color: $c-gray3;
  }
  &:focus {
    outline: none;
  }
  @media screen and (max-width: $tablet_point) {
    min-width: 100px;
    max-width: 100%;
    font-size: 1.6rem;
  }
  &.is-required {
    background-color: $c-white;
  }
  &.is_error {
    background-color: $c-error-bg;
    border: solid 1px $c-error;
  }
}

// セレクトタイプの基本スタイル
.form__select {
  width: 100%;
  height: 40px;
  padding: 0 18px 0 12px;
  font-size: 1.6rem;
  background-color: $c-white;
  border: solid 1px $c-dark3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  &:focus {
    outline: none;
  }
  @media screen and (max-width: $tablet_point) {
    min-width: 100px;
  }
  &.is-required {
    background-color: $c-white;
  }
  &.is_error {
    background-color: $c-error-bg;
    border: solid 1px $c-error;
  }
}

.form__select-small {
  @extend .form__select;

  min-width: 60px;
  padding: 10px 36px 10px 12px;
  font-size: 1.6rem;
  border-radius: 2px;
  background-color: $c-white;
  @media screen and (max-width: $tablet_point) {
    min-width: 52px;
    padding: 10px 24px 10px 12px;
  }
}

.form__textarea {
  width: 100%;
  min-width: 200px;
  height: 200px;
  padding: 12px;
  font-size: 1.6rem;
  background-color: $c-white;
  border: solid 1px $c-gray3;
  border-radius: 2px;
  @media screen and (max-width: $tablet_point) {
    min-width: 100px;
  }
  &.is-required {
    background-color: $c-white;
  }
  &::placeholder {
    // color: #575757;
    font-size: 1.6rem;
    color: #ccc;
  }
  &.is_error {
    background-color: $c-error-bg;
    border: solid 1px $c-error;
  }
}

.form__label--white {
  @extend .form__label;

  color: #333;
  &::before {
    background-color: $c-white;
  }
}

%error--check {
  input {
    &.is-error,
    &.is_error {
      .form__label {
        &::before {
          background-color: $c-error-bg;
          border: solid 1px $c-error;
        }
      }
      .form__input-check {
        &:checked {
          & ~ .form__label {
            &::before {
              background-color: $c-error;
            }
          }
        }
      }
    }
  }
}

%error--text {
  &.is-error,
  &.is_error {
    input,
    textarea,
    select {
      background-color: $c-error-bg;
      border: solid 1px $c-error;
    }
  }
}

//チェックボックス（複数）
.form--checkboxes {
  @extend .form--checkbox;

  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: $tablet_point) {
    display: block;
  }
  .form__check-wrap {
    padding-top: 4px;
    padding-left: 20px;
    @media screen and (max-width: $tablet_point) {
      padding: 0;
    }
    &:nth-of-type(n + 4) {
      padding-top: 16px;
    }
    &:nth-of-type(3n + 1) {
      padding-left: 0;
    }
    @media screen and (max-width: $tablet_point) {
      padding-left: 0;
      &:nth-of-type(n + 2) {
        padding-top: 8px;
      }
    }
  }
}

// ラジオ
.form--radio {
  @extend .form;
  @extend %error--check;
  .form__label {
    &::before {
      border-radius: 50%;
    }
    &::after {
      //top: 25%;
      left: 4px;
      width: 14px;
      height: 14px;
      background-color: $c-selected;
      border-radius: 50%;
    }
  }
  &:focus {
    & ~ .form__label {
      &::before {
        border-color: $c-selected;
      }
    }
  }
  .form__input-check {
    &:checked {
      & ~ .form__label {
        &::before {
          background-color: $c-white;
          border-color: $c-selected;
        }
      }
    }
  }
}

// テキスト
.form--text {
  @extend .form;
  @extend %error--text;
}

// テキスト
.form--select {
  @extend .form;
  @extend %error--text;
  &::after {
    position: absolute;
    top: 18px;
    right: 8px;
    display: block;
    width: 0;
    height: 0;
    content: "";
    border-color: $c-black transparent transparent transparent;
    border-style: solid;
    border-width: 6px 4px 0 4px;
  }
}
.form--select.small {
  border-width: 8px 3px 0 3px;
}

.form--textarea {
  @extend .form;
  @extend %error--text;
}

// お問い合わせ新規登録などで使われているフォーム
.form__container {
  width: 900px;
  padding: 40px 0;
  margin: 0 auto;
  @media screen and (max-width: $pc_point) {
    width: auto;
    padding: 32px 0;
  }
}
.form__text {
  font-size: 1.6rem;
  padding-bottom: 40px;
  border-bottom: 1px solid $c-teramoto-light;
  span {
    color: $c-red;
  }
  @media screen and (max-width: $pc_point) {
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 0 16px 19px 16px;
  }
}
.form__wrapper {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid $c-middleGray;
  @media screen and (max-width: $pc_point) {
    padding: 24px 16px 16px 16px;
    display: block;
  }
  +.form__wrapper {
    @media screen and (max-width: $pc_point) {
      padding-top: 16px;
    }
  }
  .form--select {
    width: 300px;
    @media screen and (max-width: $pc_point) {
      width: 100%;
    }
    .form__select {
      border: 1px solid $c-gray3;
      border-radius: 2px;
      &.is_error {
        border: solid 1px $c-error;
      }
    }
  }
  .form--text {
    width: 700px;
    @media screen and (max-width: $pc_point) {
      width: 100%;
    }
    .form__input-text{
      &::placeholder {
        font-size: 1.6rem;
      }
    }
  }
  .form--textarea {
    width: 700px;
    @media screen and (max-width: $pc_point) {
      width: 100%;
    }
  }
}
.form__category {
  width: 200px;
  font-size: 1.6rem;
  color: $c-teramoto-light;
  font-weight: bold;
  padding-top: 9px;
  @media screen and (max-width: $pc_point) {
    font-size: 1.4rem;
    padding-bottom: 8px;
    padding-top: 0;
  }
  span {
    color: $c-red;
  }
}
.form__button {
  padding-top: 32px;
  @media screen and (max-width: $pc_point) {
    padding: 24px 16px 0;
  }
  .button {
    width: 400px;
    @media screen and (max-width: $pc_point) {
      width: 100%;
      max-width: 400px;
    }
  }
  .button__medium--gray, .button__medium--red {
    width: 180px;
    margin-top: 24px;
    @media screen and (max-width: $pc_point) {
      width: 148px;
    }
  }
  .button__medium--red {
    +.button__medium--gray {
      margin-left: 24px;
    }
  }
}

// アカウント新規登録で使われているフォーム
.form__multi {
  display: flex;
  align-items: center;
}
.form__wrapper {
  align-items: center;
  .form__category {
    padding-top: 0;
  }
  &.is-multi {
    .form--text {
      width: 200px;
      @media screen and (max-width: $tablet_point) {
        width: 150px;
      }
    }
  }
  &.is-flex {
    @media screen and (max-width: $tablet_point) {
      display: flex;
    }
    .form__category {
      @media screen and (max-width: $tablet_point) {
        padding-bottom: 0;
        width: 100px;
      }
    }
    .form__label {
      font-size: 1.6rem;
    }
  }
}
.form__coname {
  font-size: 1.6rem;
  padding-left: 40px;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.4rem;
    padding-left: 16px;
  }
  &.before-input {
    color: $c-gray3;
  }
}
.form__password {
  font-size: 1.6rem;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.4rem;
  }
}
.form__button--flex {
  display: flex;
  justify-content: center;
  .button__medium--gray, .button__medium--red {
    margin: 24px 0 0;
  }
}