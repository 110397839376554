.display__setting {
  display: flex;
  justify-content: space-between;
}
.display__setting__amount {
  display: flex;
  align-items: center;
  @media screen and (max-width: $tablet_point) {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.display__sort-menu {
  font-size: 1.6rem;
  font-weight: bold;
  padding-right: 20px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    border-top: 2px solid $c-black;
    border-right: 2px solid $c-black;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    right: 0;
    top: 50%;
    margin-top: -9px;
  }
  &.is-open {
    &::after {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      margin-top: -4px;
    }
  }
}
.display__sort {
  padding-left: 40px;
  display: flex;
  @media screen and (max-width: $tablet_point) {
    display: none;
    padding-left: 0;
    padding-top: 24px;
    &.is-open {
      display: flex;
    }
  }
}
.display__sort-select {
  display: flex;
  align-items: center;
  .label__form {
    padding-bottom: 0;
    padding-right: 8px;
  }
  +.display__sort-select {
    padding-left: 16px;
  }
}

//アカウント一覧
.display__setting {
  &.is-account {
    .display__sort {
      @media screen and (max-width: $tablet_point) {
        padding-top: 0;
      }
    }
  }
}