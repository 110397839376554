.global-footer {
  padding-top: 0;
  background-color: $c-gray;
}
.global-footer__copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding-top: 0;
  font-size: 1.2rem;
  text-align: center;
  color: $c-dark2;
  small {
    font-size: 1.2rem;
  }
}
