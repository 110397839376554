.top__card {
  display: flex;
  align-items: center;
  background-color: $c-white;
  padding: 32px 24px;
  position: relative;
  box-shadow: 1px 1px 2px $c-box-shadow;
  border: 1px solid $c-white;
  min-height: 160px;
  @media screen and (max-width: $tablet_point) {
    padding: 8px 46px 8px 18px;
    min-height: 100px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 26px;
    height: 26px;
    background-color: $c-teramoto-light;
    right: 8px;
    bottom: 8px;
    border-radius: 2px;
  }
  &::after {
    position: absolute;
    content: "";
    right: 18px;
    bottom: 16px;
    width: 10px;
    height: 10px;
    border-top: 2px solid $c-white;
    border-right: 2px solid $c-white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:hover {
    border: 1px solid $c-teramoto-light;
    background-color: $c-hover-green;
    transition : 0.3s;
  }
}
.top__card__icon {
  min-width: 80px;
  text-align: center;
  @media screen and (max-width: $tablet_point) {
    min-width: 45px;
  }
}
.top__card__textarea {
  padding-left: 24px;
  @media screen and (max-width: $tablet_point) {
    padding-left: 18px;
  }
}
.top__card__title {
  font-size: 2.2rem;
  font-weight: bold;
  color: $c-teramoto-light;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.6rem;
  }
}
.top__card__text {
  font-size: 1.2rem;
  color: $c-black;
  line-height: 1.7;
  padding-top: 24px;
  @media screen and (max-width: $tablet_point) {
    padding-top: 8px;
  }
}