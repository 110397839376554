@charset "utf-8";
// -----------------------------------
// Text
// -----------------------------------
.u-text--normal {
  font-style: normal !important;
  font-weight: normal !important;
}

.u-text--bold {
  font-weight: bold !important;
}

.u-text--italic {
  font-style: italic !important;
}

.u-text--left {
  text-align: left !important;
}

.u-text--center {
  text-align: center !important;
}

.u-text--right {
  text-align: right !important;
}

// -----------------------------------
// hide /show element
// -----------------------------------
.u-hidden {
  display: none !important;
}

.u-hidden--pc {
  @media screen and (min-width: 1025px) {
    display: none !important;
  }
}

.u-hidden--ipad-pro {
  @media screen and (max-width: $pc_point_mf) {
    display: none !important;
  }
}

.u-hidden--ipad {
  @media screen and (max-width: $tablet_point) {
    display: none !important;
  }
}

.u-hidden--sp {
  @media screen and (max-width: $sp_point) {
    display: none !important;
  }
}

.u-show {
  display: block !important;
}

.u-show--pc {
  @media screen and (min-width: 1025px) {
    display: block !important;
  }
}

.u-show--ipad-pro {
  @media screen and (max-width: $pc_point_mf) {
    display: block !important;
  }
}

.u-show--ipad {
  display: none;
  @media screen and (max-width: $tablet_point_mf) {
    display: block !important;
  }
}

.u-show--sp {
  @media screen and (max-width: $sp_point) {
    display: block !important;
  }
}
.u-devider {
  margin-bottom: 33px;
  border-top: 1px solid #b3b3b3;
  @media screen and (max-width: $pc_point) {
    margin-top: 160px;
    margin-bottom: 25px;
  }
}
.u-devider--primary {
  margin-bottom: 20px;
  border-top: 1px solid #b3b3b3;
  @media screen and (max-width: $pc_point) {
  }
}
