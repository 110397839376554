.title-page {
  padding: 32px 0 0 32px;
  @media screen and (max-width: $tablet_point) {
    padding: 24px 0 0 16px;
  }
}

%title-page-child {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.title-page__name {
  @extend %title-page-child;
  display: block;
  font-size: 2.8rem;
  font-weight: bold;
  color: $c-black;
  @media screen and (max-width: $tablet_point) {
    font-size: 2.0rem;
  }
}

// 詳細ページの前のページに戻るリンク付きタイトル
.title__with-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
  @media screen and (max-width: $tablet_point) {
    padding-right: 0;
  }
  .page-back {
    padding-top: 36px;
    font-size: 1.4rem;
    text-decoration: underline;
    color: $c-blue2;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 26px;
      height: 26px;
      background-color: $c-white;
      border: 1px solid $c-gray3;
      border-radius: 2px;
      left: -34px;
      top: 32px;
    }
    &::after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid $c-black;
      border-right: 2px solid $c-black;
      -webkit-transform: rotate(225deg);
      transform: rotate(225deg);
      left: -24px;
      top: 40px;
    }
    &:hover {
      color: $c-teramoto-light;
      transition : 0.3s;
    }
  }
}

// 新規登録ボタン付きタイトル
.title__with-button {
  display: flex;
  justify-content: space-between;
}
.add__button {
  border: 1px solid $c-teramoto-light;
  background-color: $c-white;
  border-radius: 2px;
  margin: 32px 32px 0 0;
  @media screen and (max-width: $tablet_point) {
    margin: 17px 16px 0 0;
  }
}
.add__button-body {
  font-size: 1.4rem;
  font-weight: bold;
  color: $c-teramoto-light;
  display: block;
  padding: 10px 16px 10px 42px;
  position: relative;
  @media screen and (max-width: $tablet_point) {
    font-size: 1.2rem;
    padding: 10px 8px 10px 30px;
  }
  &::before {
    position: absolute;
    content: "";
    width: 18px;
    height: 2px;
    background-color: $c-teramoto-light;
    left: 13px;
    top: 50%;
    margin-top: -1px;
    @media screen and (max-width: $tablet_point) {
      width: 14px;
      left: 10px;
    }
  }
  &::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 18px;
    background-color: $c-teramoto-light;
    left: 21px;
    top: 9px;
    @media screen and (max-width: $tablet_point) {
      height: 14px;
      top: 10px;
      left: 16px;
    }
  }
  &:hover {
    background-color: $c-hover-green;
    transition: 0.3s;
  }
}