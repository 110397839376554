.product-info__display-setting {
  padding-top: 24px;
}
.product-info__table {
  padding-top: 16px;
  overflow-x: auto;
  @media screen and (max-width: $tablet_point) {
    padding-top: 24px;
  }
}
.product-info__pagenation {
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: $tablet_point) {
    justify-content: center;
    padding-top: 24px;
  }
}